.button {
	font-weight: 700;
	text-transform: uppercase;
}

.button.primary {
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.03rem;
}

a.inline-link {
	//border-bottom: 1px solid ($cc-black);
	text-decoration: underline;
	
	&.strong {
		font-weight: 700;
	}
}

a.text-link {
	//border-bottom: 1px solid ($cc-black);
	text-decoration: underline;
	
	&.strong {
		font-weight: 700;
	}
}
p > a.inline-link {
	//border-bottom: 1px solid ($cc-black);
	text-decoration: underline;
	
	&.strong {
		font-weight: 700;
	}
}

.stat {
	font-weight: 900;
	font-size: 3.5rem;
}

.subheader {
	font-size: 80%;
}

ul.strong {
	font-weight: 900 !important;
}

ul.spaced {
	li {
		margin-top: 0.2rem;
	}
}

p.bold {
	font-weight: 900 !important;
}



p.secondary {
	font-size: 0.8rem;
}

hr.no-space {
	margin-top: 0;
	margin-bottom: 0;
}

p.text-italic {
	font-style: italic;
}
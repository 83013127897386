section.scholarship-hero-unit {
	background: #222 url(https://cdn.shopify.com/s/files/1/1889/5061/files/ScolarshipHeader_2000x1200_e6d4391e-c0d4-4a2e-815c-59bc22881303.jpg?123671) center no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	color: $white;
	padding-top: 12rem;
	padding-bottom: 4rem;

	h1, p { color: $white; }
	p { font-weight: 700; }
}

section.what-it-is {
	padding: 4rem 0rem;
	
	.values {
		text-align: center;
		
		h2 {
			margin-top: 2rem;
			margin-bottom: 1rem;
		}
	}

	.value-points {
		text-align: center;
	}
}



.how-to-apply {
	background: $cc-light-gray;
	padding: 4rem 0rem;
}




// .why-sell-collage {
// 	min-height: 33vh;
// 	background: $cc-black url(../img/WhySellCollage_2000x800.jpg) center no-repeat;
// 	-webkit-background-size: cover;
// 	-moz-background-size: cover;
// 	-o-background-size: cover;
// 	background-size: cover;
	
// 	 @include breakpoint(large xlarge) {
// 		min-height: 33vh;
// 		background-attachment: fixed;
// 		background-size: contain;
// 	}
// } 

section.instagram {
	background: darken($cc-black,3%);
	padding: 2rem 0rem;
	position: relative;
	
	h2 { 
		color: $white; 
		text-align: center; 
		margin-bottom: 2rem;
	}
	
	svg.instagram-icon { display: block; margin: 0 auto; fill: $cc-orange; }

	i { color: $cc-orange; }
	
	#instafeed {
		
		a {
			width: 100%;
			max-width: 50%;
			display: inline-block;
			position: relative;
			transition: all 0.2s ease-out;
			

			@include breakpoint(medium large xlarge) {
				max-width: 25%;
			}
			&:hover {
				opacity: 0.7;
			}

			div.likes {
				position: absolute;
				bottom: 0rem;
				right: 0.25rem;
				color: $cc-light-gray;
				display: inline-block;
				line-height: normal;
				font-weight: 700;
				font-size: 0.8rem;
				padding: 0.25rem 0.35rem;

				i { color: $white; }
			}
		}
	}

	.follow-cta {
		p { color: $cc-light-gray; margin-top: 1rem; }
	}
}
section.careers-hero-unit {
	background: #222 url(https://cdn.shopify.com/s/files/1/1889/5061/files/cupbg2000.jpg?123657) center no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	color: $white;
	padding-top: 12rem;
	padding-bottom: 4rem;

	h1, p { color: $white; }
	p { font-weight: 700; }
}


section.our-values {
	padding: 4rem 0rem;

	.value-points {
		text-align: center;
		.columns {
			margin-top: 1rem;
		}
	}
}


.watch-shop {
	min-height: 47vh;
	background: url(https://cdn.shopify.com/s/files/1/1889/5061/files/Nicole_2000x1200_c0d77c9f-95e5-424e-b965-888fbce2948e.jpg?123657) center no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	 @include breakpoint(large xlarge) {
		background-attachment: fixed;
	}
}


section.our-teams {
	padding: 4rem 0rem;
	
	h3 {
		color: $cc-orange;
	}

	.columns {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
}


section.benefits {
	padding: 2rem 0rem;
	background: url(https://cdn.shopify.com/s/files/1/1889/5061/files/BoxWall2000x1333.jpg?123657) center no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	.callout {
		background: rgba($white,0.95);
		margin-bottom: 0;

		h3 {
			color: $cc-orange;
		}
	}
}


section.open-positions {
	padding: 4rem 0rem;
}








.paycore-embed {

	.gnewtonCareerBodyClass {
	max-width:100% !important;
	font-family:  sans-serif;
	text-align: left !important;
	}
	.gnewtonCareerBodyClass .gnewtonCareerGroupHeaderClass {
	    width: 100%;
	    font-size: 1.5em !important;
	    text-transform: uppercase;
	    font-weight: 600;
	    text-align: left !important;
		font-family: 'Montserrat',Helvetica,sans-serif;
	}
	.gnewtonCareerBodyClass #gnewtonCareerHome a {
	    color: $cc-gray;
	    font-size: 1em !important;
	    line-height: 1em;
		font-family: 'Roboto', Helvetica,sans-serif;
	  }
	.gnewtonCareerBodyClass .gnewtonCareerGroupJobDescriptionClass {
	    float: left !important;
	    width: 49% !important;
	    flex: 0 0 auto !important;
	    overflow: hidden;
	    text-align: left !important;
	    text-overflow: ellipsis;
	    color: $cc-gray;
	    font-size: 1em !important;
	    text-decoration: none;
	    padding-top: 1px;
	    line-height: 1em;
	    padding: 3px 6px !important;
	}
	.gnewtonCareerGroupJobTitleClass {
	   -webkit-flex: none !important;
	    width: 50% !important;
	    padding: 6px 0 !important;
	    text-align: left !important;
	font-family: 'roboto',Helvetica,sans-serif;
	}
	.gnewtonCareerGroupRowClass{
	width:100%;
	word-break: break-all;
	}
	#gnewtonJobDescription > b {
	    color: $cc-gray;
	}
	#gnewtonJobDescription{
	    color: $cc-gray;
	    font-size: 1.2em;
	    font-weight: 400;
	    line-height: 1.3em;
	    font-family: 'roboto',Helvetica,sans-serif;
	}
	.gnewtonCareerBodyClass * {
	font-size: 17px  !important;
	}
	i {
	    font-style: normal;
	}
	#gnewtonCareerBody div.gnewtonBlueBtn, #gnewtonCareerBody button.gnewtonBlueBtn {
		background: #fff none repeat scroll 0 0 !important;
	    border: 2px solid #ff5a00 !important;
	    color: #444444 !important;
	    display: inline-block;
	    font-family: "Avenir Next","Lato",Calibri,Helvetica,sans-serif;
	    font-size: 14px;
	    font-weight: 600;
	    line-height: 1em;
	    min-width: 160px;
	    padding: 9px 25px;
	    text-align: center;
	    text-transform: none;
	    vertical-align: middle;
	    white-space: nowrap;
	}
	.gnewtonCareerBodyClass table {
	    width: 100% !important;
	}
	#gnewtonJobDescriptionBtn {
	    text-align: center;
	}
	#gnewtonCareerBody div.gnewtonBlueBtn, #gnewtonCareerBody button.gnewtonBlueBtn:hover {
	   background: #ff5a00 none repeat scroll 0 0 !important;
	    border: 2px solid #ff5a00 !important;
	    color: #fff !important;
	    cursor: pointer !important;
	    text-decoration: none !important;
	}
	#gnewtonLogo {
	    display: none;
	}
	#gnewtonBackToHome > td {
	text-align: center !important;
	}
	#gnewtonBackToHome > td a {
	color:#272612 !important;
	}
	#gnewtonBackToHome > td a :hover {
	color:#ff5a00 ;
	}
	.gnewtonForm {
	    max-width: 100% !important;
	    font-size: 17px  !important;
	    font-family: "Avenir Next","Lato",Calibri,Helvetica,sans-serif;
	    color: $cc-gray !important;
	    font-weight: 400 !important;
	    line-height: 1.8em !important;
	}
	.gnewtonForm b {
	    color: $cc-gray !important;
	    font-size: 17px  !important;
	    font-family: "Avenir Next","Lato",Calibri,Helvetica,sans-serif;
	    color: $cc-gray !important;
	    font-weight: 600 !important;
	    line-height: 1.8em !important;
	}
	.gnewton-intro-ul {
	    color: $cc-gray !important;
	    font-size: 17px  !important;
	    font-family: "Avenir Next","Lato",Calibri,Helvetica,sans-serif;
	    color: $cc-gray !important;
	    font-weight: 600 !important;
	    line-height: 1.3em !important;
	}
	#gnewtonBackToHome > td a:hover {
	    color: #ff5a00 !important;
	}
	#backToCareerHome a, .back a {
	    color: #272612 !important;
	    padding: 5px 0;
	    text-decoration: none;
	}
	#backToCareerHome a:hover{
	    color: #ff5a00 !important;
	   }
	#gnewtonBackToHome a:hover{
	color: #ff5a00 !important;
	  }
	#gnewton-submit{
	   display: block !important;
	   margin: 0 auto;
	}
	#backToCareerHome a, .back a {
	    display: block !important;
	    text-align: center !important; 
	}
	.back{
	text-align: center;
	color: #272612 !important;
	}
	.back:hover{
	color: #ff5a00 !important;
	}
	.gnewtonFieldLabelClass {
	    text-align: right;
	    width: 392px !important;
	}
	.gnewtonFieldInputClass, .gnewtonQuestionInputClass
	{
	margin: 0 auto !important;
	}
	#gnewtonBackToHome > div {
	    text-align: center !important;
	    color:#272612 !important;
	}
	#e3BackLink1 a{
	color:#272612 !important;
	}
	#resumeDrop label#resumeDropLocalFile .remoteFiles {
	    color: #272612 !important;  
	}
	.no-touch #resumeDrop #resumeDropLocalFile, #resumeDrop .remoteFiles span {
	    color: #272612 !important;
	}
	.no-touch #resumeDrop #resumeDropLocalFile:hover, #resumeDrop .remoteFiles span:hover {
	    color: #ff5a00 !important;
	}
	label.emptyFileInput {
	    color: #272612 ;
	    }
	label.emptyFileInput:hover {
	    color: #ff5a00 ;
	    }
}


section.fd-header {
	background-color: #ed492e;
	@include gradient(linear,left,rgb(237, 73, 46),rgb(240, 104, 36),'left center, right center');
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1,StartColorStr='#ed492e', EndColorStr='#f06824');
	color: $white;
	padding-top: 2rem;
	padding-bottom: 2rem;

	h1, p { color: $white; }
	p { font-weight: 700; }
}



section.email-cta {
	background: $cc-smoke;
	padding: 2rem 0rem;
	margin-top: 2rem;
}

div.hs-form-field {
	display: inline-block;
	max-width: 100%;
	.input {
		margin: 1rem;
	}
	input.hs-input {
		display: block;
		margin: 0.5rem auto;
	}
}




.hs-button {
	@include button-style;
	border: none;
	outline: none;
	padding: 0.5rem 1rem;
	text-transform: uppercase;
	letter-spacing: 0.04rem;
	font-weight: 900;
}
ul.hs-error-msgs {
	list-style: none;
	transform: translateY(-1rem);
}
label.hs-error-msg {
	font-size: 0.8rem;
	color: red;
}




section.about-candc {
	padding: 2rem 0rem;
}




section.how-it-works-fd {
	.row {
		margin-bottom: 2rem;
	}
}
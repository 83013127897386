section.our-story-hero-unit {
	background: #222 url(https://cdn.shopify.com/s/files/1/1889/5061/files/10and2-background.jpg?123657) center top no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	color: $white;
	padding-top: 12rem;
	padding-bottom: 4rem;
	position: relative;

	h1, p { color: $white; }

	p { font-weight: 700; }
}


section.the-story {
	padding: 4rem 0rem 0rem 0rem;
	p.lead {
		font-weight: 700;
	}
	p {
		margin-bottom: 3rem;
	}
	h2 {
		margin-bottom: 1rem;
	}

}


.our-story-feature-one  {
	min-height: 33vh;
	background: $cc-black url(https://cdn.shopify.com/s/files/1/1889/5061/files/AU_CollageOurStory.jpg?123657) center no-repeat; //url(../img/cc-logo-icon-bg.png)
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	 @include breakpoint(large xlarge) {
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		//background-attachment: fixed;
	}
}

section.our-values {
	padding: 4rem 0rem;

	h2 {
		margin-bottom: 1rem;
	}

	.value-points {
		text-align: center;
		.columns {
			margin-top: 1rem;
		}
	}
}

section.cc-difference-points {
	background: $cc-smoke;
	padding-bottom: 3rem;
	padding-top: 2rem;

	.cc-difference {
		max-width: 100%;
		
		@include breakpoint(large xlarge) {
			max-width: 73%;
		}
		.difference-point {
			margin-bottom: 3rem;
		}
		
		
		p { font-weight: 700; font-size: 0.9rem; }
	}

}


section.our-leadership {
	//padding: 0rem 0rem 4rem 0rem;
	// background: $cc-smoke;
	
	.profile {
		margin-top: 1rem;	
	}

	.leadership-card {
		position: relative;
		// &:after {
		// 	width: 100%;
		// 	height: 20px;
		// 	color: $cc-gray;
		// 	display: block;
		// 	z-index: 1;
		// 	position: absolute;
		// 	content: "\f05a";
		// 	font-family: 'Font Awesome 5 Free';
		// }
	}

	h2 {
		margin-bottom: 1rem;
	}

	h3 {
		margin-top: 1rem;
	}


}


 
section.giving-back-hero-unit {
	background: #222 url(https://cdn.shopify.com/s/files/1/1889/5061/files/MAPHero_2000x1200_baa96945-f276-4b1a-957d-a8ae29349482.jpg?123657) center no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	color: $white;
	padding-top: 12rem;
	padding-bottom: 4rem;
	//min-height: 75vh;

	h1, p { color: $white; }
	p { font-weight: 700; }
}


section.how-we-give-back {
	padding: 4rem 0rem;
}

	.stat-block {
		position: relative;
		
		// &:after {
		// 	content: "+";
		// 	font-size: 3rem;
		// 	display: inline-block;
		// 	color: $cc-light-gray;
		// 	position: absolute;
		// 	top: 10%;
		// 	left: 100%;
			
		// }
		// &:first-of-type {
		// 	&:after {
		// 		content: "";
		// 	}
		// }
		// &:last-of-type {
		// 	&:after {
		// 		content: "";
		// 	}
		// }
	}

section.map-video {
	//background: rgba($cc-gray,0.1);
	background: $cc-black;
	padding: 1rem 0rem;
}

section.how-we-help {
	padding: 4rem 0rem;
	
	.features {
		//background: $cc-orange;
	}
}


.stats {
	margin: 2rem 0rem 2rem 0rem;
}



// .watch-shop {
// 	min-height: 47vh;
// 	background: url(../img/Nicole_2000x1200.jpg) center no-repeat;
// 	-webkit-background-size: cover;
// 	-moz-background-size: cover;
// 	-o-background-size: cover;
// 	background-size: cover;

// 	 @include breakpoint(large xlarge) {
// 		background-attachment: fixed;
// 	}
// }


// section.our-teams {
// 	padding: 4rem 0rem;

// 	.columns {
// 		margin-top: 1rem;
// 		margin-bottom: 1rem;
// 	}
// }


// section.benefits {
// 	padding: 2rem 0rem;
// 	background: url(../img/BoxWall2000x1333.jpg) center no-repeat;
// 	-webkit-background-size: cover;
// 	-moz-background-size: cover;
// 	-o-background-size: cover;
// 	background-size: cover;

// 	.callout {
// 		background: rgba($white,0.95);
// 	}
// }


// section.open-positions {
// 	padding: 4rem 0rem;
// }
section.why-sell-hero-unit {
	background: #222 url(https://cdn.shopify.com/s/files/1/1889/5061/files/WhySellHero_2000x1200_ab27b74a-c2b7-4a1f-9b30-41fce4cb153a.jpg?123671) center no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	color: $white;
	padding-top: 12rem;
	padding-bottom: 4rem;

	h1, p { color: $white; }
	p { font-weight: 700; }
}

section.how-it-works {
	padding: 4rem 0rem 0rem 0rem;

	.row > .columns	{
		margin-bottom: 2rem;
	}
}

.why-sell-collage {
	min-height: 33vh;
	background: $cc-black url(https://cdn.shopify.com/s/files/1/1889/5061/files/why-sell-collage.jpg?123671) center no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	position: relative;
	
	 @include breakpoint(large xlarge) {
		min-height: 33vh;
		background-attachment: fixed;
		// -webkit-background-size: contain;
		// -moz-background-size: contain;
		// -o-background-size: contain;
		// background-size: contain;
	}
}

.recent-survey {
	padding: 2rem 0rem 0rem 0rem;
}

section.how-we-do-it-sell {
	padding: 2rem 0rem 0rem 0rem;
	background: $cc-smoke;

	h3 {
		color: $cc-orange;
	}
	.row > .columns {
		margin-bottom: 2rem;
	}
}


section.press {
	padding: 2rem 0rem;
}


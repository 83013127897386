section.why-buy-hero-unit {
	background: #222 url(https://cdn.shopify.com/s/files/1/1889/5061/files/WhyBuy_2000x1200_d877f762-2d31-4035-83d9-ea4fef38948d.jpg?123671) center no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	color: $white;
	padding-top: 12rem;
	padding-bottom: 4rem;

	h1, p { color: $white; }
	p { font-weight: 700; }
}

section.how-we-do-it-buy {
	padding: 2rem 0rem;

	.row > .columns	{
		margin-bottom: 2rem;
	}
	
	h3 {
		color: $cc-orange;
	}
}

.why-buy-collage {
	min-height: 33vh;
	background: $cc-black url(https://cdn.shopify.com/s/files/1/1889/5061/files/Charlie_2000x1200_e025db4a-f5d1-4224-bbd6-830d9fd2b4a3.jpg?123671) center no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	
	 @include breakpoint(large xlarge) {
		min-height: 33vh;
		background-attachment: fixed;
		background-size: cover;
	}
} 
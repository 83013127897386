section.home-hero {
	background: #222; // url(../img/crown-pattern.png) repeat;
	position: relative;
	width: 100%;
	height: 100%;

	.hero-wrapper {
		position: relative;
		background: url(../img/SeaSpeed_image_1800x800.jpg) center no-repeat;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		width: 100%;
		min-height: 33vh;
	}

	h1, h2, p { color: $white; }
	p { font-weight: 700; }
}

.value-prop {
	border-top: 1px solid $cc-light-gray;
	padding: 0.5rem;
	position: relative;

	.about-btn {
		display: block;
		text-align: center;
		position: relative;
		color: $dark-gray;
		display: none;
			i {
				color: $cc-orange;
			}
		
		@include breakpoint(large xlarge) {  
			position: absolute;
			display: inline-block;
			font-size: 1rem;
			text-transform: uppercase;
			top: 38%;
			right: 5%;
		}
	}

	h2 {
		margin-bottom: 0;
	}

	p.sell-pts {
		text-align: center;
		font-size: 0.8rem;
		color: $dark-gray;
		margin-bottom: 0;
	}
}

// .bs-wrapper {
// 	min-height: 100vh;
// }

.buy, .sell {
	background: rgba($cc-orange,0.8);
	padding: 0.5rem 0rem;
	//border: 1px solid darken($cc-orange,20%); // 

	@include breakpoint(medium large xlarge) { 
		padding: 3rem 0rem;
	}
	a.text-link {
		display: block;
		color: $white;
		text-decoration: underline;
		margin-top: 0rem;
		font-size: 1rem;
	}
	.button {
		&:hover, &:focus {
			color: $white !important;
		}
	}
}

.buy {
	position: relative;


	@include breakpoint(medium large xlarge) { 
		 &:after {
		content: "";
		width: 60%;
		height: 3px;
		background: $white;
		position: absolute;
		top: 100%;
		left: 20%;
		// display: block;
		// margin: 0 auto;
	}
	}
	
}

section.popular-brands {
	background: #2259a8;
	color: $white;
	padding: 1.5rem;

	p, a, h2 {
		color: $white;
	}

	a {
		text-decoration: underline;
	}

	ul.menu {
		font-size: 1rem;
	}
}
section.new-arrivals {
	padding: 3rem;
}




section.about-us-leads {
	padding: 4rem 3rem;
	margin-top: 3rem;
	margin-bottom: 1rem;
	width: 100%;
	background: url(../img/people.jpg) center no-repeat; //watchmakes
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	h2, p {
		color: $white;
	}
}





.menu.soc-links a {
	padding: 0rem 0.5rem;
}



// for comp not production
.site-header {
	background: url(../img/site-header-bg.jpg) top repeat-x;
}


// for comp not production
.site-footer {
	background: url(../img/site-footer-bg.jpg) top repeat-x;
}



.overflow-hidden {
	overflow-x: hidden;
	border-right: 1px solid $white;
	ul {
		width: 1000px;
	}
}

a.view-all-brands {
	font-size: 0.8rem;
	margin-top: 0.45rem;
	display: inline-block;
}


section.reviews {
	background: $cc-black;

	p.review-rating {
		font-family: "Montserrat";
		font-weight: 900;
		color: $white;
		font-size: 2rem;
		text-align: center;
	}

	ul.review-stars {
		list-style: none;
		color: $cc-orange;
		text-align: center;
		max-width: 180px;
    	display: block;
    	margin: 0 auto;
		margin-bottom: 1rem;

		li {
			display: inline-block;
			padding: 0rem;
			width: 100%;
			max-width: 18%;
		}
	}
	
	img.review-source {
		max-width: 130px;
		margin-bottom: 0.75rem;
	}
	p.review {
		color: $cc-gray;
		text-align: center;
	}
	h3.reviewer {
		text-align: center;
		color: $white;
	}
	p.review-date {
		color: $cc-orange;
		font-size: 1.1rem;
		font-weight: 700;
		text-align: center;
	}
}



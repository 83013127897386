

img.aligncenter {
	margin: 0 auto;
	display: block;
}

.orange {
	color: $cc-orange;
}


.shadow {
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.callout.no-border {
	border: none;
}

svg {
	max-width: 100%;
	display: block;
}

figure {
	position: relative;
	
	figcaption {
		position: absolute;
		font-size: 0.8rem;
		color: $white;
		top: 80%;
		left: 10%;
	}
}

.full-bleed {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  
  	.fb-wrapper {
  		max-width: 50rem;
  		margin-left: auto;
  		margin-right: auto;
	}
}





section.reviews {
	h2 {
		color: $white;
	}
}



.reviewshakeWidget__reviewUserProfile__name {
	font-weight: bold;
	color: $cc-orange;
}

.reviewshakeWidget__reviewText {
	font-size: 0.9rem;
	margin-bottom: 0.5rem;
	color: $cc-gray;
}

.reviewshakeWidget__reviewBottom {
	div {
		font-size: 0.8rem;
		color: $cc-gray;
		float: left;
	}
	img {
		max-width: 100px;
		float: right;
	}
}

.reviewshakeWidget__reviewFooter__time {
	font-weight: bold;
	color: $cc-orange;
}
 

.reviewshakeWidget__review {
	padding: 1rem;
	//border: 1px solid rgba($cc-gray,0.3);
}


.reviewshakeWidget__starIcon {
	display: inline-block;

	i { color: #f9a41a; }
}

// .reviewshakeWidget__rating,
// .reviewshakeWidget__reviewUserProfile__name {
// 	display: inline-block;
// 	max-width: 50%;
// }

// .reviewshakeWidget__rating {
// 	float: right;
// }

.reviewshakeWidget__reviewUserProfile,
.reviewshakeWidget__reviewText,
.reviewshakeWidget__reviewFooter__time {
	margin-bottom: 1.5rem;
}

.reviewshakeWidget__reviewUserProfile__name {
	font-size: 1.1rem;
}
header#proc-header {
	margin-bottom: 1rem;
	border-bottom: 1px solid $cc-gray;
	background: $cc-light-gray;
}


footer#proc-footer {
	background: $cc-light-gray;
	color: $cc-gray;
}

.rtip-iframe-container {
  width:100%;
  margin-bottom: 20px;
}
section.cc-carousel {
	padding: 3rem 0rem;
	position: relative;
}

.cc-horiz-carousel {
	position: relative;
	max-width: 94%;
	display: block;
	margin: 0 auto;
	margin-bottom: 4rem;
}


.cc-horiz-carousel-wrapper {
	position: relative;
	margin-bottom: 3rem;
	margin-top: 3rem;
	width: 100%;
	
	.cc-carousel-nav {
		position: relative;
		width: 104px;
		height: 52px;
		display: block;
		margin: 0 auto;
		margin-top: 0.5rem;
		z-index: 1001;
		transform: translateY(26px);
	}
	.cc-horiz-next,
	.cc-horiz-prev {
		display: inline-block;
		width: 50px;
		height: 50px;
		border: 1px solid $black;
		color: $cc-black;
		background: rgba($white,0.95);
		text-align: center;
		font-size: 1.2rem;
		cursor: pointer;
		transition: all 0.3s;
		&:hover,
		&:focus {
			background: rgba($white,1.0);
		}

		i { 
			display: block; 
			padding: 1rem 0rem; 
		}
	}
}





// ul.custom-dots {
//     position: absolute;
//     top: -5rem;
//     list-style: none;
//     display: block;
//     text-align: center;
//     padding: 0;
//     margin: 0;
//     width: 100%;
//     color: $cc-orange;

//     li {
//         display: inline-block;
//         width: 33%;
//         button {
//             text-indent: -1000px;
//             width: 100px;
//             height: 5px;
//             background: $cc-gray;
//             overflow: hidden;
//         }
//     }
//     li.slick-active {

//         button {
//             text-indent: -1000px;
//             width: 100px;
//             height: 5px;
//             background: $cc-orange;
//         }
//     }
// }




/*** Magool ***/
ul.custom-dots {
	position: relative;
	
	li {
		line-height: 1;
		position: relative;
		display: block;
		margin: 0;
		padding: 0;
		letter-spacing: 0;
		color: currentColor;
		border: 0;
		background: none;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
		width: 1.5em;
		height: 1.25em;
		text-indent: -1000px;
	}

	li::after {
		content: '';
		position: absolute;
		top: 45%;
		left: 0;
		width: 100%;
		height: 10%;
		background: $cc-gray;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		-webkit-transition: -webkit-transform 0.5s, background-color 0.5s;
		transition: transform 0.5s, background-color 0.5s;
		-webkit-transition-timing-function: cubic-bezier(0.2,1,0.3,1);
		transition-timing-function: cubic-bezier(0.2,1,0.3,1);
	}

	li:not(.slick-active):focus::after,
	li:not(.slick-active):hover::after {
		background: $cc-orange;
	}

	li:not(.slick-active):hover::after {
		-webkit-transform: scale3d(2,1,1);
		transform: scale3d(2,1,1);
	}

	li.slick-active::after {
		background: $white;
		-webkit-transform: scale3d(2,1,1);
		transform: scale3d(2,1,1);
	}
}
// .cc-vert-carousel-container {
// 	position: relative;
// 	width: 100%;
// 	background: #eee;
// 	padding: 3rem 0rem;

// 	.cc-carousel-nav {
// 		position: absolute;
// 		width: 50px;
// 		height: 100px;
// 		top: 50%;
// 		right: 30px;
// 		z-index: 1001;
// 	}
// 	.cc-carousel-next,
// 	.cc-carousel-prev {
// 		display: block;
// 		width: 50px;
// 		height: 50px;
// 		border: 1px solid $black;
// 		color: $cc-black;
// 		background: rgba($white,0.8);
// 		text-align: center;
// 		font-size: 1.2rem;
// 		cursor: pointer;
// 		transition: all 0.3s;
// 		&:hover,
// 		&:focus {
// 			background: rgba($white,1.0);
// 		}

// 		i { 
// 			display: block; 
// 			padding: 1rem 0rem; 
// 		}
// 	}
// 	.cc-carousel-prev {
// 		margin-bottom: 2px;
// 	}
	
// }
// .cc-vert-carousel {
// 	position: relative;

// 	div {
// 		min-height: 70vh;
// 	}
// }



/*----------  Fade in Animation  ----------*/

.fade-in {
	animation-name: fade-in;
	animation-fill-mode: both;
	animation-duration: .5s;
}
$animationDelay: 1;
@for $i from 1 through 15 {
	.projects div:nth-of-type(#{$i}) {
		animation-delay: #{0.3+($i)/30}s;
  }
}
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}


/*----------  Ripple Effect on Button Click  ----------*/

[ripple] {
  position: relative;
  overflow: hidden;
}
[ripple] .ripple--container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
[ripple] .ripple--container span {
  transform: scale(0);
  border-radius: 100%;
  position: absolute;
  opacity: 0.75;
  background-color: $cc-orange;
  animation: ripple 1000ms;
}
@-moz-keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2);
  }
}
@-webkit-keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2);
  }
}
@-o-keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2);
  }
}
@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2);
  }
}




/*----------  Gradient Mixin  ----------*/

$webkit:true;
$o:true;
$moz:true;
$w3:true;
$ms:true;

//Gradient Mixin
@mixin gradient($mode,$direction,$from,$to,$oldDirection:null){

    @if $webkit == true {
        @if($oldDirection != null){
            background-image: -webkit-gradient($mode, #{$oldDirection}, from($from),to($to));
        }
        background-image: -webkit-#{$mode}-gradient($direction, $from, $to);
    }
    @if $moz == true {
        background-image: -moz-#{$mode}-gradient($direction, $from, $to);
    }
    @if $o == true {
        background-image: -o-#{$mode}-gradient($direction, $from, $to);
    }
    @if $ms == true {
        background-image: -ms-#{$mode}-gradient($direction, $from, $to);
    }
    @if $w3 == true {
        background-image: #{$mode}-gradient($direction, $from, $to);
    }
}



/*----------  Page Section Base Styles  ----------*/

.hero {
	background: $cc-black;
}


section.dark {
	background: $cc-black;
	width: 100%;
	padding: 3rem 0rem;
	position: relative;
	
	// &.with-separator {
	// 	margin-top: 6rem;
	// 	position: relative;
	// 	background: $cc-black;
	 
	// 	&:before {
	// 		content: "";
	// 		background: url(../img/castle-separator.svg) center repeat-x;
	// 		height: 120px;
	// 		width: 100%;
	// 		top: -80px;
	// 		left: 0;
	// 		position: absolute;
	// 		z-index: -1;
	// 	}
	// }

	h1, h2, h3, h4, h5 { color: $cc-smoke; }

	p { color: $white; }
}




























section.press {
	padding: 4rem 0rem;
	
	.row {
		margin-bottom: 1.2rem;
	}
	.row > .columns {
		margin-bottom: 2rem;
	}
	

}

